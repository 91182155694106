<template>
  <div class="container">
    <navigation msg="评论"></navigation>
    <div class="content4">
      <div>
        <span class="commentContent">评价单位</span>
        <img class="sj" src="../../assets/sj.png" alt="">
        <span class="sj1">{{commentDetail.add_time}}</span>
      </div>
      <div style="margin-top:0.3rem;">
        <div class="content3">
          <img src="../../assets/gs.png" alt="">
          <span>评价公司：{{companyName == undefined ? "暂无" : companyName}}</span>
        </div>

        <div class="content3">
          <img src="../../assets/person.png" alt="">
          <span>评价人：{{commentDetail.username == null ? "暂无" :commentDetail.username}}</span>
          <span class="slice">|</span>
          <span>职务：{{commentDetail.positions == null ? "暂无" : commentDetail.positions}}</span>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="content5">
        <div class="disstyle">
          <div class="commentContent">评论内容</div>
          <div class="tag" @click="status(1,commentDetail.id)">{{commentDetail.is_open == 2 ? "开启" : "隐藏"}}</div>
        </div> 
        <div class="content1">{{commentDetail.is_open == 2 ? '******************' : commentDetail.content}}</div>
      </div>
      
      <div class="commentContent">回评</div>
      <div class="recommentList" v-if="myComment.length > 0 ">
        <div class="recommentItem"  v-for="(item1,index) in myComment" :key="index">
          <div  style="width: 0.70rem;height: 0.7rem;">
              <img style="width: 0.70rem;height: 0.7rem;"  src="../../assets/user_1.jpg" alt="">
          </div>
          <div class="right">
            <div style="display:flex;justify-content:space-between;">
              <div class="name">{{item1.name}}</div>
              <div  class="tag"  @click="status(2,item1.id)">{{item1.comment_set == 1 ? "隐藏" : "开启"}}</div>
             
            </div>
            
            <div class="content2">{{item1.comment_set == 1 ? item1.content : "****************"}}</div>
            <div v-if="item1.comment_set != 1">{{item1.type == 1 ? "企业已屏蔽" : "员工已屏蔽"}}</div>
            
            <div style="margin:0.2rem 0">评价时间：{{item1.add_time}}</div>
          
          </div>
        </div>
        <div style="height:2rem;"></div>
        <div  class="bts1"> 
          <div class="btn9" @click="recomment">我要回评</div>
        </div>
       
        
      </div>
      <div class="no" v-else>
        <img  src="../../assets/recomment1.png" alt="">
        <div class="nomsg">
          - 您还没有任何回评 -
        </div>
        <div  class="bts1"> 
          <div class="btn9" @click="recomment">我要回评</div>
        </div>
      </div>
    </div>
     <van-popup v-model="popShow" position="bottom" :style="{ height: '5.6rem'}" >
      <div class="select">状态设置</div>
      <div class="day" @click="isStatus(2)">屏蔽</div>
      <div class="day" @click="isStatus(1)" style="margin-top:0.5rem">公开</div>
    </van-popup>
    
  </div>
</template>
<script>
import navigation from "../../components/navigation/index"
import { Toast } from "vant";
export default {
  components:{
    navigation
  },
  data(){
    return{
      commentDetail:{},
      myComment:[],
      praise:[],
      companyName:"",
      popShow:false,
      isContent:"", //判断是 主消息 还是回评消息
      type:"",
      replyId:""
    }
  },
  methods:{
    recomment(){
      this.$router.push({path:"./recomment",query:{id:this.commentDetail.id}})
    },
    status(type,id){
      // console.log(type,id)
      this.replyId = id
      this.isContent = type
      this.popShow = true
    },
    isStatus(type){
      let evaluationId = this.commentDetail.id //消息id
      let messageType = this.isContent // 消息类型，主消息还是回评消息
      let replyCommentId;
      if(messageType != 1){
        replyCommentId = this.replyId
      }else{
        replyCommentId = ""
      }
      
      // console.log(type, evaluationId,messageType,replyCommentId)
      this.$http.post("/user/v1/pro_files/shieldMessage",{
        reqType:"shieldMessage",
        type:type,
        evaluationId:evaluationId,
        messageType:messageType,
        replyCommentId:replyCommentId || ""})
      .then((res)=>{
        // console.log(res)
        let response = JSON.parse(res.data)
        if(response.code == 0){
        //  console.log(response)
         Toast.success("保存成功")
         setTimeout(()=>{
           this.$router.go(-1)
         },1000)
        this.popShow = false 
        }else{
          Toast.success(response.msg)
        }
        
      })
      .catch((e)=>{console.log(e)})
      
    },
    getDetail(){
      let { id, companyName, type } = this.$route.query
      this.type = type
      this.companyName = companyName
      this.$http.post("/user/v1/pro_files/commentDetail",{reqType:"commentDetail",id:id,})
      .then((res)=>{
        let response = JSON.parse(res.data)
        // console.log(response)
        if(response.code == 0){
          console.log(response)
          let {commentDetail,myComment} = response.data
          this.commentDetail= commentDetail
          this.myComment = myComment
        }else{
          Toast.fail(response.msg)
        }
        })
      .catch((e)=>{console.log(e)})
      }
  },
  mounted(){
    // console.log(this.$route.query.id1,this.$route.query.type1)
    if(this.$route.query.type1 == "msg"){
      this.$http.post("/user/v1/pro_files/commentDetail",{reqType:"commentDetail",id:this.$route.query.id1,})
      .then((res)=>{
        let response = JSON.parse(res.data)
        // console.log(response)
        if(response.code == 0){
          // console.log(response)
          let {commentDetail,myComment} = response.data
          this.companyName = this.$route.query.comp
          this.commentDetail= commentDetail
          this.myComment = myComment
        }else{
          Toast.fail(response.msg)
        }
        })
      .catch((e)=>{console.log(e)})
    }else{
      this.getDetail()
    }
   
  } 
}
</script>
<style scoped>
.content5{
 padding-bottom:0.2rem;
border-bottom:0.01rem solid #ddd;
margin-bottom:0.4rem;
}
.slice{
  margin:0 0.2rem;
}
.content4{
  margin:0.4rem 0.48rem;
  padding-bottom:0.2rem;
  border-bottom:0.01rem solid #ddd;
}
.content3{
  margin:0.15rem 0;
  font-size: 0.24rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #979797;
}
.content3 img{
  width:0.28rem;
  height:0.28rem;
  margin-right:0.2rem;
}

.sj1{
  font-size: 0.24rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #989898;

}
.sj{
  width:0.22rem;
  height:0.22rem;
  margin:0 0.1rem 0 0.26rem;
}
.container{
  position: fixed;
  height:100%;
  width:100%;
  background-color: #fff;
  top: 0;
  bottom:0;
  overflow-y:scroll;
  overflow-x:hidden;
  }
  .commentContent{
    font-size: 0.42rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    
}

.content{
  margin:0 0.48rem;
  margin-top:0.28rem;
}
.disstyle{
  display:flex;
  justify-content: space-between;
  align-items: center;
}
.tag{
  width: 0.84rem;
  height: 0.42rem;
  color:#666;
  border-radius: 0.08rem;
  border: 0.02px solid #666;
  text-align: center;
  line-height: 0.42rem;
  margin-top:0.06rem;
}
.content1{
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6F6F6F;
  margin-top:0.2rem;
  margin-bottom:0.3rem;
}
.content2{
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6F6F6F;
  margin-top:0.29rem;
  /* margin-bottom:; */
  word-wrap:break-word;
}
.recommentItem{
  display:flex;
}
.right{
  width:90%;
  margin-left:0.2rem;
}
.recommentList{
  margin-top:0.5rem;
  /* height:6.8rem;
  overflow-y:auto; */
 
}
.name{
  font-size: 0.3rem;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #6F6F6F;
margin-top:0.1rem;
}
.bts1{
  position: fixed;
  left:0;
  bottom:0;
  width: 100%;
  height:1.5rem;
  background:#fff;
  padding-bottom:0.1rem;
}
.btn9{
  
  width: 6.5rem;
  height: 1rem;
  line-height: 1rem;
  text-align: center;
  border-radius: 0.16rem;
  border: 0.05rem solid #FF5F19   !important;
  /* margin-left:0.2rem; */
  margin: 0.2rem auto 0;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FF5F19;
  background-color:#fff;
  margin-bottom:0.4rem;
}
.recommentItem{
  margin-top:0.2rem;
}
.select{
  font-size: 0.34rem;
  color: #333333;
  margin-bottom:0.71rem;
   margin-top:0.48rem;
}
.day{
  height: 1rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  border-bottom: 0.01rem solid #ddd;
}
.select, .day{
  text-align: center;
}
.no{
  text-align: center;
  margin-top:2rem;
}
.no img{
  width:1.5rem;
  height:1.5rem;
}
.nomsg{
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6F6F6F;
  margin-top:0.3rem;
}

</style>